/*  =============== adding fonts =============== */
@font-face {
  font-family: 'Ubuntu';
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/ubuntu/Ubuntu-Regular.ttf');
}
@font-face {
  font-family: 'Ubuntu';
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/ubuntu/Ubuntu-Bold.ttf');
}
@font-face {
  font-family: 'Ubuntu';
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/ubuntu/Ubuntu-Medium.ttf');
}
@font-face {
  font-family: 'Ubuntu';
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/ubuntu/Ubuntu-Light.ttf');
}
/*  =============== adding fonts =============== */
