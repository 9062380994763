@import 'assets/styles/variables';

.app {
  .main {
    display: flex;
    border-radius: 0.8rem;
    padding: 1.7rem;
    height: calc(100vh - 175px);
    position: relative;
  }
}