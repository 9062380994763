@import "./variables";

// ==== General ====
.hidden {
  display: none;
}

.invisible {
  opacity: 0;
}

.hr-separator {
  width: 40rem;
  height: 0.5rem;
  background-color: #778899;
  border: none;
  margin: 2rem 0rem;
}

// ==== Typography ====

.light-typo {
  font-weight: 100 !important;
}

// ===== Widgets =====

.widget {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 1rem 1.5rem;
  overflow: auto;
  position: relative;
  &.border {
    border: solid 2px $color-cloudy-blue;
  }
}

//responsive images
.img-responsive {
  max-width: 100%;
}

.loading-icon {
  position: absolute;
  background-color: rgba($color: white, $alpha: 0.5);
  z-index: 100;
  img {
    width: 5rem;
  }
}
