// colors
$color-primary: #3e48a6;
$color-light-grey: #f8f9fb;
$color-grey: #b6bcd1;
$color-dark-grey: #7d7c97;
$color-red: #ca3232;
$color-green: #19a784;
$color-orange: #f4aa39;
$color-cloudy-blue: #b6bcd1;

$color-label-red: #e29596;
$color-label-green: #19a784;
$color-label-orange: #e5952c;

$color-main-bg: #fff;
$color-main-border: 1px solid #254158;

// fonts
$font-family: Ubuntu, Roboto, sans-serif;
