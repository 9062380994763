@import 'assets/styles/variables';
@import 'assets/styles/utils';

body {
  background-color: $color-light-grey;
  font-family: $font-family;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}
