// ==== brandDisplay.js ====

.image-radius {
  border-radius: 3rem;
  object-fit: contain;
}

// Text Font Styles

.small-brand-name {
  font-size: 12px !important;
}

.medium-brand-name {
  font-size: 15px !important;
}

.large-brand-name {
  font-size: 18px !important;
}

//Margin Styles

.text-margin-left {
  // Overwritten by .MuiTypography-root with margin 0
  margin-left: 1rem !important;
}

.text-margin-top {
  // Overwritten by .MuiTypography-root with margin 0
  margin-top: 0.5rem !important;
}

.align-text {
  text-align: center;
}
